import __request__ from 'routing/request';
interface KinesisHealthCheckResponse {
    readonly message_fields: {
        readonly [_key: string]: Object;
    };
    readonly input_type: 'KINESIS_RAW' | 'KINESIS_CLOUDWATCH_RAW' | 'KINESIS_CLOUDWATCH_FLOW_LOGS' | 'UNKNOWN';
    readonly explanation: string;
}
interface AWSRequestImpl {
    readonly dynamodb_endpoint: string;
    readonly aws_access_key_id: string;
    readonly kinesis_endpoint: string;
    readonly aws_secret_access_key: unknown;
    readonly assume_role_arn: string;
    readonly iam_endpoint: string;
    readonly cloudwatch_endpoint: string;
    readonly region: string;
}
interface LogGroupsResponse {
    readonly log_groups: string[];
    readonly total: number;
}
interface RegionsResponse {
    readonly total: number;
    readonly regions: AWSRegion[];
}
interface AWSRegion {
    readonly label: string;
    readonly value: string;
}
interface StreamsResponse {
    readonly total: number;
    readonly streams: string[];
}
interface Object {
}
interface KinesisHealthCheckRequest {
    readonly dynamodb_endpoint: string;
    readonly stream_name: string;
    readonly aws_access_key_id: string;
    readonly kinesis_endpoint: string;
    readonly aws_secret_access_key: unknown;
    readonly assume_role_arn: string;
    readonly iam_endpoint: string;
    readonly cloudwatch_endpoint: string;
    readonly region: string;
}
interface AWSInputCreateRequest {
    readonly dynamodb_endpoint: string;
    readonly batch_size: number;
    readonly aws_secret_access_key: unknown;
    readonly iam_endpoint: string;
    readonly add_flow_log_prefix: boolean;
    readonly enable_throttling: boolean;
    readonly stream_name: string;
    readonly aws_access_key_id: string;
    readonly aws_input_type: string;
    readonly kinesis_endpoint: string;
    readonly assume_role_arn: string;
    readonly name: string;
    readonly cloudwatch_endpoint: string;
    readonly region: string;
}
/**
 * Get all available AWS CloudWatch log groups names for the specified region.
 */
export function getLogGroupNames(JSONbody: AWSRequestImpl): Promise<LogGroupsResponse> {
    return __request__('POST', '/aws/cloudwatch/log_groups', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Create a new AWS input.
 */
export function create(JSONbody: AWSInputCreateRequest): Promise<unknown> {
    return __request__('POST', '/aws/inputs', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Attempt to retrieve logs from the indicated AWS log group with the specified credentials.
 */
export function kinesisHealthCheck(JSONbody: KinesisHealthCheckRequest): Promise<KinesisHealthCheckResponse> {
    return __request__('POST', '/aws/kinesis/health_check', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all available Kinesis streams for the specified region.
 */
export function getKinesisStreams(JSONbody: AWSRequestImpl): Promise<StreamsResponse> {
    return __request__('POST', '/aws/kinesis/streams', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get all available AWS regions
 */
export function getAwsRegions(): Promise<RegionsResponse> {
    return __request__('GET', '/aws/regions', null, {}, {
        'Accept': ['application/json']
    });
}
